import React from 'react'
import Link from 'gatsby-link'
import cx from '../../utils/cx'
import { graphql } from 'gatsby'

import WorkContainer from '../../components/WorkContainer'
import Layout from '../../components/Layout'
import { ProjectTitle, EnglishOnly } from '../../components/Text'
import Metadata from '../../components/Metadata'
import CustomerLogo from '../../components/CustomerLogo'
import ProjectImage from '../../components/ProjectImage'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import routes from '../../components/Header/routes'

export default function TreeDiff({ data }) {
  const { t } = useTranslation()

  const project = data.projectsJson

  return (
    <Layout>
      <Metadata title={t(project.title)} isArticle />
      <WorkContainer>
        <CustomerLogo project={project} />
        <ProjectTitle title={t(project.title)} />
        <EnglishOnly />
        <ProjectImage data={project.gallery.main} top />
        <h2>Challenge</h2>
        <p>
          How do we support written negotiation? Imagine Alice makes a lot of
          modifications in a document and then asks Bob to review them. To do a
          sound review, Bob needs to understand each individual change and agree
          or disagree on it. For minor changes, a classical "track changes"
          feature achieves this. However, when Alice re-organizes whole sections
          inside a deeply nested document and also edits them, "track change" is
          not enough. Bob loses the ability to review individual changes.
        </p>
        <p>
          But without an understanding of the changes, Bob and Alice cannot
          agree.
        </p>
        <blockquote>An algorithm to enable negotiation</blockquote>
        <p>
          The challenge is then: Build an algorithm that presents all changes
          between two nested documents as a list of separate, intuitive changes
          which can be individually reviewed.
        </p>
        <h2>Approach</h2>
        <p>
          A comparison of two structured documents should result in a series of{' '}
          <em>insert</em>, <em>delete</em>, <em>update</em>, and <em>move</em>{' '}
          operations which, if all are accepted, transform one document (Bobs
          original version) into the other (Alices proposed version).
        </p>{' '}
        <blockquote>
          Make large document changes <em>human-understandable</em>
        </blockquote>
        <p>
          The algorithm needs to be smart: If Alice deletes a sentence and then
          re-writes the same sentence somewhere else, it's not useful for Bob to
          see that one sentence was deleted and a new one was created.
        </p>
        <p>
          Instead, Bob should see that the sentence was moved - maybe with minor
          changes. In summary: we are looking for the most{' '}
          <em>human-understandable</em> explanation of the changes.
        </p>
        <blockquote>Literature research</blockquote>
        <p>
          In the literature, this problem is known under the name "Tree-to-tree"
          correction problem:
          <ul>
            <li>
              <em>A tree</em> here is a mathematical concept: lines connected
              with dots called <em>nodes</em>, and no loops.
            </li>
            <li>
              Just like real trees, "no loops" means that starting on any
              branch, a walking bug has exactly one way off the tree.
            </li>
            <li>
              Nested documents are mathematical trees, where the nodes are text,
              and the lines show how sections are nested under other sections.
            </li>
          </ul>
        </p>
        <p>
          To find the difference between documents, we then need to solve two
          problems:
          <ol>
            <li>
              How to recognize nodes that are the same, even if modified and
              moved?
            </li>
            <li>
              How to present the transformation of nodes between the documents
              as a list of individually negotiable changes?
            </li>
          </ol>
        </p>
        <blockquote>When are nodes the same?</blockquote>
        <p>
          In other words, the key question is: When are two nodes across
          different document versions the same? To answer this, we might check:
          Do the nodes look similar? Do they share ancestors? Are they located
          at similar positions in a tree? Do they share the same children?
        </p>
        <p>
          Once we know the answer, we need to find a series of transformations
          that change the document from the original arrangement of nodes to a
          newly proposed one.
        </p>
        <p>
          There is diverse scientific literature on this topic. There are no
          ready-made solutions, different algorithms such as{' '}
          <a
            href="https://ieeexplore.ieee.org/document/4339230"
            target="_blank"
            rel="noreferrer"
          >
            Change Distiller
          </a>{' '}
          or{' '}
          <a
            href="https://dl.acm.org/doi/10.1145/1030397.1030399"
            target="_blank"
            rel="noreferrer"
          >
            3DM
          </a>{' '}
          emphasize different aspects of a solution.
        </p>
        <blockquote>A pragmatic solution first, then build on it</blockquote>
        <p>
          We started out with a pragmatic solution to these questions,
          continuously refining the approach and adding more sophisticated
          functionality as we iterated a product with customers.
        </p>
        <p>
          The current solution incorporates multiple approaches in both a
          cooperative and a competitive manner to find the best solution in a
          wide range of scenarios. Additionally, a three-way comparison gives us
          information about the document history, necessary for presenting
          changes in an even more intuitive way. The resulting changes are
          processed as a special "edit script" containing the individual
          changes. The document with the overlaid proposals remains editable.
        </p>
        <h2>Result</h2>
        <p>
          We are currently opening up TreeDiff as a programming API that your
          software product can consume.
        </p>
        <p>
          <Link
            className="rounded bg-blue-high px-3 py-2 text-white transition-colors ease-out group-hover:bg-gray-400 group-hover:text-white"
            to={`/#${routes.contact.id}`}
          >
            Learn more
          </Link>
        </p>
      </WorkContainer>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    projectsJson(name: { eq: "treeDiff" }) {
      name
      title
      customer
      customerLogo {
        childImageSharp {
          gatsbyImageData
        }
      }
      tags
      link
      gallery {
        main {
          alt
          src {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
